





















import { NamespaceListPageTs } from './NamespaceListPageTs';
export default class NamespaceListPage extends NamespaceListPageTs {}
